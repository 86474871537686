<template>
  <div class="text-right">
    <v-select
      hide-details
      v-if="!customLabel"
      outlined
      dense
      :items="items"
      item-text="name"
      return-object
      v-model="selected"
      :label="title ? title : 'Select Days'"
    >
      <template v-slot:append-item>
        <v-divider class="mt-2"></v-divider>
        <v-list-item ripple @click="othersBox">
          <v-list-item-content>
            <v-list-item-title>Others</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>

    <v-chip
      v-if="customLabel"
      @click:close="customLabel = false"
      class="pa-2"
      color="grey lighten-4"
      label
      close
    >
      <v-avatar color="grey lighten-2" left>
        <v-icon small>mdi-calendar-multiple-check</v-icon>
      </v-avatar>
      <span>{{ selected.name }}</span>
    </v-chip>
    <v-dialog v-model="others" width="450">
      <v-card>
        <ModelTitle title="Custom Day" @close="cancel()" />
        <v-container class="pa-5" grid-list-lg>
          <v-text-field
            hide-details
            rounded
            outlined
            dense
            type="number"
            v-model="customDay"
          />
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            class="v-btn-with-radius"
            @click.stop.prevent="submitDay"
          >
            {{ $t("buttons.go") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "day-picker",
  props: ['initValue', 'title'],
  data: () => ({
    selected: null,
    others: false,
    customLabel: false,
    customDay: null,
    items: [
      {
        id: "now",
        name: "Now",
        value: 0
      },
      {
        id: "1Day",
        name: "1 Day",
        value: 1
      },
      {
        id: "7Days",
        name: "7 Days",
        value: 7
      },
      {
        id: "15Days",
        name: "15 Days",
        value: 15
      },
      {
        id: "30Days",
        name: "30 Days",
        value: 30
      },
      {
        id: "60Days",
        name: "60 Days",
        value: 60
      },
      {
        id: "90Days",
        name: "90 Days",
        value: 90
      },
      {
        id: "365Days",
        name: "365 Days",
        value: 365
      },
    ]
  }),
  created(){
    if(this.initValue){
      this.selected = this.initValue
      const result = this.items.find(({ id }) => id == this.initValue.id);
      if(!result){
        this.customLabel = true
      }
    }
  },
  watch: {
    selected(val) {
      if(val){
        this.$emit("input", val)
      }
    }
  },
  methods: {
    cancel() {
      this.others = !this.others;
      this.customDay = null
    },
    othersBox() {
      this.others = !this.others;
    },
    submitDay(){
      this.selected = {
        id: this.customDay > 1 ? this.customDay+'Days' : this.customDay+'Day',
        name: this.customDay > 1 ? this.customDay+' Days' : this.customDay+' Day',
        value: this.customDay
      }
      this.customLabel = true
      this.others = !this.others;
    },
  }
}
</script>