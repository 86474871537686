<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card grid-list-md>
      <ModelTitle title="New Customer" @close="close()" />

      <v-card-text>
        <v-row>
          <v-col cols="12" xs="12" sm="12">
            <v-text-field :error-messages="nameErrors" v-model="customer.name" autofocus
              :background-color="`${(customer.name) ? false : $style.inputBox.mandatory}`" label="Name"
              @change="customer.name = properCase(customer.name)" :hide-details="nameErrors.length === 0" outlined
              @focus="$event.target.select()" dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field v-model="customer.contactName" outlined hide-details dense
              @change="customer.contactName = properCase(customer.contactName)" @focus="$event.target.select()"
              label="Contact Name"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field outlined dense hide-details class="right-input" @focus="$event.target.select()"
              v-model="customer.balanceLimit" label="Balance Limit"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field outlined hide-details dense @focus="$event.target.select()" v-model="customer.cellNumber"
              label="Cell Number"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field outlined hide-details dense @focus="$event.target.select()" v-model="customer.phoneNumber"
              label="Phone Number"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">

            <v-text-field outlined hide-details dense v-model="customer.email" label="Email Address"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field outlined hide-details dense v-model="customer.taxId" label="Tax ID"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <DayPicker v-model="customer.metadata.invoiceDue" :initValue="customer.metadata.invoiceDue"
              :title="'Customer Terms'" />
          </v-col>

          <v-col cols="12" sm="12">
            <v-card outlined>
              <v-tabs v-model="selectedTab">
                <v-tab>Billing</v-tab>
                <v-tab>Shipping</v-tab>
                <v-tab-item>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-text-field outlined hide-details dense v-model="customer.address"
                          label="Address"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field outlined hide-details dense @change="customer.city = properCase(customer.city)"
                          v-model="customer.city" label="City"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field outlined dense hide-details v-model="customer.state" label="State"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field outlined hide-details dense v-model="customer.zip"
                          label="Zip Code"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" sm="12" v-if="customer.address">
                        <v-btn outlined small @click="copyBillingAddressToShipping">Same as billing</v-btn>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-text-field outlined hide-details dense v-model="customer.shippingAddress"
                          label="Address"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field outlined hide-details dense
                          @change="customer.shippingCity = properCase(customer.shippingCity)"
                          v-model="customer.shippingCity" label="City"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field outlined dense hide-details v-model="customer.shippingState"
                          label="State"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field outlined hide-details dense v-model="customer.shippingZip"
                          label="Zip Code"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs>
            </v-card>


          </v-col>

          <v-col cols="12" sm="12">
            <SalesMan v-model="selectedUser" :rounded="false" :initValue="initUser" :disabled="checkRightStatus(50)" />
          </v-col>
          <v-col cols="12" sm="12">
            <v-textarea outlined hide-details dense auto-grow v-model="customer.note"
              :label="$t('labels.note')"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <span class="mr-2">Balance</span>
        <toggle-button :width="60" :labels="{ checked: 'Show', unchecked: 'Hide' }" v-model="customer.showBalance"
          :value="customer.showBalance" :sync="true">
        </toggle-button>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
        <v-btn color="blue darken-1" :loading="loading" dark @click="submit()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import customerService from "../service.js";
import GlobalFunction from "@/mixins/GlobalFunction";
import SalesMan from "@/modules/User/components/AutoComplete.vue";
import DayPicker from "@/components/DayPicker";

const { validationMixin } = require("vuelidate");
const { required } = require("vuelidate/lib/validators");

export default {
  props: {
    createDialog: {
      default: false
    },
    createFromStore: {
      type: Boolean
    }
  },
  name: "create-customer",
  data() {
    return {
      initUser: null,
      selectedTab: 0,
      foundError: false,
      loading: false,
      dialog: false,
      selectedUser: null,
      customer: {
        showBalance: true,
        name: null,
        cellNumber: null,
        email: null,
        phoneNumber: null,
        contactName: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        shippingAddress: null,
        shippingCity: null,
        shippingState: null,
        shippingZip: null,
        note: null,
        SalesRepUserId: null,
        metadata: {
          invoiceDue: null,
        }
      }
    };
  },
  mixins: [validationMixin, GlobalFunction],
  validations: {
    customer: {
      name: {
        required
      }
    }
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.customer.name.$dirty) return errors;
      if (!this.$v.customer.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));

      return errors;
    }
  },
  watch: {
    createDialog(val) {
      this.dialog = val;
      setTimeout(() => {
        if (this.checkRightStatus(50)) {
          this.initUser = this.currentUser
        }
      }, 300);

    },
    selectedUser(val) {
      if (val && this.dialog) {
        this.customer.SalesRepUserId = val.id
      }
    },
  },
  components: {
    SalesMan,
    DayPicker
  },
  methods: {
    // properCase(string) {
    //   var sentence = string.toLowerCase().split(" ");
    //   for (var i = 0; i < sentence.length; i++) {
    //     sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    //   }
    //   return sentence.join(" ");
    // },
    copyBillingAddressToShipping() {
      this.customer.shippingAddress = this.customer.address
      this.customer.shippingCity = this.customer.city
      this.customer.shippingState = this.customer.state
      this.customer.shippingZip = this.customer.zip
    },
    close() {
      this.empty();
      this.$emit("close");
    },
    empty() {
      this.customer = {
        name: null,
        email: null,
        phoneNumber: null,
        contactName: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        note: null,
        SalesRepUserId: null,
        metadata: {
          invoiceDue: null,
        }
      };
      this.initUser = null
      this.selectedUser = null
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields")
          });
      } else {
        // if (!this.customer.SalesRepUserId) {
        //   this.$swal
        //     .mixin({
        //       toast: true,
        //       position: "top-end",
        //       showConfirmButton: false,
        //       timer: 3000,
        //     })
        //     .fire({
        //       icon: "error",
        //       title: "Sales Rep not selected!",
        //     });
        //   return false;
        // }
        this.loading = true;
        return customerService
          .create(this.customer)
          .then(result => {
            if (result.status === "success") {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000
                })
                .fire({
                  icon: "success",
                  title: "customer is created"
                });
              this.loading = false;
              this.$emit("submit");
              this.$events.emit("customerListEvent");
              this.close();
            } else {
              this.loading = false;
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "error",
                  title: result.data.message ? result.data.message : "Internal Server Error"
                });
              this.close();
            }
          })
          .catch(err => {
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: err.data.message
              });
          });
      }
    }
  }
};
</script>

<style scoped></style>
